import { Box } from "@chakra-ui/react"
import { SearchFilters } from "@components/Search/Filters/SearchFilters"
import { memo } from "react"

const CollectionSideBarFiltersDesktop: React.FC = ({ collection }) => {
  return (
    <>
      <Box py={4} pr={4} bg="brand.lightSand" w={52} display={["none", "none", "block"]}>
        <SearchFilters collection={collection} />
      </Box>
    </>
  )
}

const MemoCollectionSideBarFiltersDesktop = memo(CollectionSideBarFiltersDesktop)
export { MemoCollectionSideBarFiltersDesktop as CollectionSideBarFiltersDesktop }
