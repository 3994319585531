import { memo, useEffect, useMemo } from "react"
import { SearchResults } from "@components/Search/Results/SearchResults"
import { Box, Container, HStack, Text } from "@chakra-ui/react"
import { AlgoliaSearchProvider } from "@app/providers/algolia"
import { CollectionContent } from "./CollectionContent"
import { ListingHeader } from "@app/components/Listing/ListingHeader"
import { CollectionPromotionalCards } from "./CollectionPromotionalCards"
import { SearchSort } from "@components/Search/Sort/SearchSort"
import { SearchFilterTopCollection } from "@components/Search/Filters/SearchFilterTopCollection"
import { CollectionSideBarFiltersDesktop } from "./CollectionSideBarFiltersDesktop"
import { usePageCollection } from "@app/hooks/usePageCollection"
import Sections from "@components/Sections/Sections"

import type { Props } from "@app/templates/collection"
import type { PageProps } from "@root/types/global"
import { useCore } from "@app/hooks/useCore"

const Collection: React.FC<PageProps<Props>> = ({ location, collection, template }) => {
  const { getPageCollection } = usePageCollection({ collection })
  const { item, headerContent, bottomContent } = useMemo(() => getPageCollection(), [getPageCollection])
  const {
    helpers: { isBrowser },
  } = useCore()

  return isBrowser && location && collection ? (
    <>
      <AlgoliaSearchProvider location={location} key="collection" collection={collection}>
        <ListingHeader title={item?.title}>
          <Text maxW="970px" mb={4} lineHeight="21px" fontSize="sm" dangerouslySetInnerHTML={{ __html: headerContent }} />
          <SearchFilterTopCollection collection={collection} />
        </ListingHeader>
        {/* Bg gradient below is a tricky workaround to get left and right box colors to bleed to screen edge properly */}
        <Box bgGradient={["unset", "unset", "linear(to-r, brand.lightSand 0 50%, brand.offWhite 50% 100%)"]}>
          <Container as={HStack} alignItems="stretch" spacing={[0, 0, 4]}>
            <CollectionSideBarFiltersDesktop collection={collection} />
            <Box py={4} pl={[0, 0, 4]} bg="brand.offWhite" flex={1} maxW="full">
              <CollectionPromotionalCards cards={collection?.promotionalCards} />
              <SearchSort collection={collection} />
              <SearchResults />
              <CollectionContent content={bottomContent} />
            </Box>
          </Container>
        </Box>
      </AlgoliaSearchProvider>
      {template?.content?.length && <Sections page={template} />}
    </>
  ) : null
}

export default memo(Collection)
