import { useAlgoliaSearch } from "@app/providers/algolia"
import { HStack } from "@chakra-ui/react"
import { useStringHelpers } from "@app/hooks/useStringHelpers"
import { SelectableTag } from "@components/SelectableTags"
import { memo } from "react"

interface Collection {
  headerFilter: string
}

interface Facets {
  [key: string]: {
    [key: string]: number
  }
}

type SearchFilterTopCollectionProps = {
  collection: Collection
}

type SearchFilterTopCollectionButtonProps = {
  checked: boolean
  name: string
}

type SearchFilterTopCollectionButtonsProps = {
  collection: Collection
}

const SearchFilterTopCollectionButton: React.FC<SearchFilterTopCollectionButtonProps> = ({ checked, name }) => {
  const { deHandleize } = useStringHelpers()

  const filterTitle = deHandleize(name)
  const { handleTopFilterChange } = useAlgoliaSearch()
  return <SelectableTag label={filterTitle} value={filterTitle} checked={checked} handleClick={() => handleTopFilterChange(name)} />
}

const SearchFilterTopCollectionButtons: React.FC<SearchFilterTopCollectionButtonsProps> = ({ collection }) => {
  const collectionHeaderFilter = `named_tags.${collection?.headerFilter}`
  const { facets, selectedTopFilters } = useAlgoliaSearch<Facets>()

  return (
    <HStack wrap="wrap" gap={2} spacing={0}>
      <SearchFilterTopCollectionButton key="show-all" checked={selectedTopFilters.length === 0} name="show-all" />
      {collectionHeaderFilter &&
        facets[collectionHeaderFilter] &&
        typeof facets[collectionHeaderFilter] === "object" &&
        Object.keys(facets[collectionHeaderFilter]).map(filter => (
          <SearchFilterTopCollectionButton key={filter} checked={selectedTopFilters.includes(filter)} name={filter} />
        ))}
    </HStack>
  )
}

const SearchFilterTopCollection: React.FC<SearchFilterTopCollectionProps> = ({ collection }) => {
  return (
    <>
      <SearchFilterTopCollectionButtons collection={collection} />
    </>
  )
}

const MemoSearchFilterTopCollection = memo(SearchFilterTopCollection)
export { MemoSearchFilterTopCollection as SearchFilterTopCollection }
